body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.btn {
  text-align: center;
  background-color: lightblue;
  height: 140px;
  width: 140px;
  border-radius: 50%;
  margin: 10px;
  border: none;
  box-shadow: 0 9px #999;
  text-align: center;
  cursor: pointer;
  outline: none;
  padding: 15px 25px;

}

.btn:hover {
  background-color: rgb(139, 173, 184)
}

.btn:active {
  background-color: rgb(139, 173, 184);
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

.button {
  padding: 15px 25px;
  text-align: center;
  cursor: pointer;
  outline: none;
  color: #fff;
  background-color: lightblue;
  border: none;
  border-radius: 15px;
  box-shadow: 0 9px #999;
}

.button:hover {
  background-color: rgb(139, 173, 184)
}

.button:active {
  background-color: rgb(139, 173, 184);
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}